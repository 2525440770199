import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';

// NGRX
import { Store } from '@ngrx/store';
import { PortalPages } from '../../../models/portal/portal-pages';
import { PortalState } from '../../../store/reducers/portal.reducer';

import {  map, takeUntil } from 'rxjs/operators';
import { environment } from 'projects/iam/src/environments/environment';
import { portalQuery } from '../../../store/selectors/portal.selectors';
import { DefaultUserGroups } from '../../../models/default-user-groups.interface';
import { DestroyService } from '../../../libs/shared/services/destroy.service';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'iam-nav-items',
    templateUrl: './nav-items.component.html',
    styleUrls: ['./nav-items.component.scss'],
    providers: [DestroyService],
    standalone: false
})
export class NavItemsComponent implements OnInit {
  @Input() isSidenav: boolean;
  @Output() sidenavClose = new EventEmitter();

  portalPages = PortalPages;

  userGroups = DefaultUserGroups;
  envName = environment.environmentName;
  onMobile: boolean;

  loggedIn$ = this.store.select(portalQuery.loggedInToPortal);
  isServiceManager$ = this.store.select(portalQuery.userHasBusinessRole('SERVICE_MANAGER'));
  isCoupled$ = this.store.select(portalQuery.getOrganizations).pipe(map(organizations => organizations?.length > 0));

  notifications$ = this.store
    .select(portalQuery.getNotifications)
    .pipe(
      map(
        ({ pending_users, change_business_info_request, quarterly_review }) =>
          pending_users + change_business_info_request + (typeof quarterly_review == 'number' ? quarterly_review : 0)
      )
    );

  openChangeRequest$ = combineLatest([
    this.store.select(portalQuery.getChangeRequest),
    this.store.select(portalQuery.getSubmittedChangeRequest),
  ]).pipe(map(([changeRequest, submittedChangeRequest]) => Boolean(changeRequest || submittedChangeRequest)));

  constructor(
    private unsubscribe$: DestroyService,
    private store: Store<PortalState>,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: BreakpointState) => (this.onMobile = state.matches));
  }

  onSidenavClose(): void {
    if (this.isSidenav) {
      this.sidenavClose.emit();
    }
  }
}
