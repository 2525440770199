<mat-toolbar color="primary" id="page-nav" class="items-center justify-center" data-test="header"
    *ngIf="!(hideNavMenu$ | async)">
    <div class="flex flex-row w-11/12 lg:w-9/12 justify-between">
        <div data-test="navigation" class="flex items-center justify-between">
            <div class="md:hidden flex-none">
                <button mat-icon-button (click)="onToggleSidenav()">
                    <i class="fa-light fa-bars fa-lg"></i>
                </button>
            </div>
            <iam-nav-items data-test="nav-toolbar" class="md:contents hidden"></iam-nav-items>
            <iam-organization-selector class="md:hidden ml-8"></iam-organization-selector>
        </div>
    </div>
</mat-toolbar>