import { Component } from '@angular/core';
import { environment } from 'projects/iam/src/environments/environment';

@Component({
    selector: 'iam-beamer-button',
    templateUrl: './beamer-button.component.html',
    styleUrls: ['./beamer-button.component.scss'],
    providers: [],
    standalone: false
})
export class BeamerButtonComponent {
  environment = environment
}
