import { Component } from '@angular/core';

// NgRx
import { select, Store } from '@ngrx/store';
import { Login, Logout } from '../../../auth/store/actions/auth.actions';
import { portalQuery } from '../../../store/selectors/portal.selectors';
import { PortalState } from '../../../store/reducers/portal.reducer';

// RXJS
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'iam-login-button',
    templateUrl: './login-button.component.html',
    standalone: false
})
export class LoginButtonComponent {
  private loaded$ = this.store.pipe(select(portalQuery.loadedPortal));

  loggedIn$ = this.store.pipe(select(portalQuery.loggedInToPortal));

  showLoader$ = combineLatest([this.loaded$, this.loggedIn$]).pipe(
    map((loaded, loggedIn) => !Boolean(loaded) && !Boolean(loggedIn))
  );

  constructor(private store: Store<PortalState>) {}

  logout(): void {
    this.store.dispatch(Logout());
  }

  login(): void {
    this.store.dispatch(Login());
  }
}
