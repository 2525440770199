<footer id="page-footer" class="flex flex-row flex-grow justify-center">
  <div class="flex flex-row w-11/12 lg:w-9/12 justify-between">

    @if(!(onMobile$ | async) || showFooterOnMobile){
    <div class="flex flex-col md:flex-row gap-8">
      <small class="leading-5"><a [href]="'footer.termsAndConditionsLink' | translate" target="_blank">{{ 'footer.termsAndConditions' |
          translate
          }}</a></small>
      <small class="leading-5"><a [href]="'footer.privacyStatementLink' | translate" target="_blank">{{ 'footer.privacyStatement' |
          translate
          }}</a></small>

      <small class="leading-5"><a routerLink="/docs/cookies">{{ 'footer.cookieStatement' |
          translate
          }} </a></small>


      <small class="leading-5"><a [href]="'footer.responsibleDisclosureLink' | translate" target="_blank">{{
          'footer.responsibleDisclosure' |
          translate
          }}</a></small>

      <small class="leading-5"><a [href]="'footer.disclaimerLink' | translate" target="_blank">{{
          'footer.disclaimer' |
          translate
          }}</a></small>
    </div>
    }

    <span class="powered_by invisible md:visible">
      <img src="assets/img/logo.svg" />
    </span>
    
 
    <span class="block md:hidden" (click)="showFooterOnMobile = !showFooterOnMobile">
      @if(showFooterOnMobile){
      <i class="fa-light fa-chevron-down fa-xl"></i>
      }
      @else {
      <i class="fa-light fa-chevron-up fa-xl"></i>
      }
    </span>
  </div>
</footer>