import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DrawerModule } from 'primeng/drawer';

import { CustomLoader } from './translation/custom-loader';
import { MDModule } from './../md.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { NavComponent } from './components/nav/nav.component';
import { NavItemsComponent } from './components/nav-items/nav-items.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import {
  GetOrgByIdPipe,
  OrganizationSelectorComponent,
} from './components/organization-selector/organization-selector.component';

import { LocaleToLanguageIdPipe } from './translation/localeToLanguageId.pipe';

import { AuthenticationGuard } from './guards/authentication-guard';
import { ReferenceDataService } from './services/reference-data.service';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { FeedbackButtonComponent } from './components/customer-service-button/customer-service-button.component';
import { LetDirective, PushPipe } from '@ngrx/component';
import { BeamerButtonComponent } from './components/beamer-button/beamer-button.component';

import { NotFoundComponent } from './components/notfound/notfound.component';
import { AuthenticationService } from './services/authentication.service';

import { LogoutComponent } from './components/logout/logout.component';

import { SkeletonLoaderModule } from '../libs/shared/components/iam-skeleton-loader/skeleton-loader.module';
import { DirectivesModule } from '../libs/directives/src/lib/directives.module';
import { GenericFormComponentsModule } from '../libs/generic-form-components/src/lib/generic-form-components.module';
import { PortbaseDialogModule } from '../libs/portbase-dialog-component/lib/portbase-dialog.module';
import { EnsureModuleLoadedOnceGuard } from '../libs/shared/classes/ensureModuleLoadedOnceGuard';
import { QuarterlyReviewNotificationComponent } from './components/quarterly-review-notification/quarterly-review-notification.component';
import { ButtonModule } from 'primeng/button';
import { NoticeBarModule } from './components/notice-bar/notice-bar.module';
import { UserGroupGuard } from './guards/usergroup-guard';
import { FeaturetoggleGuard } from './guards/featuretoggle-guard';
import { PkceService } from './services/pkce.service';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    MDModule,
    RouterModule,
    PortbaseDialogModule,
    SkeletonLoaderModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [],
      },
    }),
    GenericFormComponentsModule,
    ReactiveFormsModule,
    LetDirective,
    PushPipe,
    MatListModule,
    MatCheckboxModule,
    DrawerModule,
    ButtonModule,
    NoticeBarModule,
    FooterComponent
  ],
  declarations: [
    HeaderComponent,
    NavComponent,
    NavItemsComponent,
    ContentComponent,
    LoginButtonComponent,
    BeamerButtonComponent,
    NotFoundComponent,
    LogoutComponent,
    LocaleToLanguageIdPipe,
    LanguageSelectorComponent,
    UserNameComponent,
    OrganizationSelectorComponent,
    CookieBannerComponent,
    FeedbackButtonComponent,
    GetOrgByIdPipe,
    QuarterlyReviewNotificationComponent
  ],
  providers: [AuthenticationGuard, AuthenticationService, ReferenceDataService, UserGroupGuard, FeaturetoggleGuard, PkceService],

  exports: [
    HeaderComponent,
    NavComponent,
    NavItemsComponent,
    ContentComponent,
    FooterComponent,
    LoginButtonComponent,
    BeamerButtonComponent,
    LocaleToLanguageIdPipe,
    CookieBannerComponent,
    QuarterlyReviewNotificationComponent
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
  // Look for the module in the parent injector to see if it's already been loaded
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
