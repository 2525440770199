import { Component, OnInit } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';

// RXJS
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

// Services
import { DestroyService } from '../../../libs/shared/services/destroy.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'iam-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [DestroyService],
    standalone: false
})
export class HeaderComponent implements OnInit {
  onMobile: boolean;

  hideNavMenu$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => {
      let route = this.activatedRoute.firstChild;
      let child = route;
      while (child) {
        if (child.firstChild) {
          child = child.firstChild;
          route = child;
        } else {
          child = null;
        }
      }
      return route;
    }),
    mergeMap(route => route.data),
    map(data => Boolean(data?.hideNavMenu))
  );

  constructor(
    private unsubscribe$: DestroyService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: BreakpointState) => (this.onMobile = state.matches));
  }
}
