import { Injectable } from '@angular/core';

// RXJS
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

// NGRX
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

// Services
import { ReferenceDataService } from '../../core/services/reference-data.service';
import {
  loadReferenceData,
  referenceDataLoaded,
  referenceDataLoadError,
} from '../actions/references.actions';
import { referencesQuery } from '../selectors/references.selectors';

@Injectable()
export class ReferencesEffects {
  constructor(
    private actions$: Actions,
    private refService: ReferenceDataService,
    private store: Store
  ) {}

  loadReferences$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadReferenceData),
        mergeMap(action => {
          const hasLoaded$ = this.store.pipe(
            select(referencesQuery.hasOrganizationTypesLoaded)
          );

          return hasLoaded$.pipe(
            mergeMap(loaded => {
              if (!loaded) {
                // @ts-ignore
                return this.refService
                  .getReferenceData(action.refType, action.active)
                  .pipe(
                    map(result =>
                      referenceDataLoaded({
                        refType: action.refType,
                        payload: result,
                      })
                    ),
                    catchError(error =>
                      of(
                        referenceDataLoadError({
                          refType: action.refType,
                          error: error,
                        })
                      )
                    )
                  );
              }
              return EMPTY;
            })
          );
        })
      ),
    { dispatch: true }
  );
}
