<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation" class="sidenav-container">
    <iam-nav-items
      (sidenavClose)="sidenav.close()"
      [isSidenav]="true"
    ></iam-nav-items>
  </mat-sidenav>

  <mat-sidenav-content class="page-container">
    <iam-header></iam-header>
    <iam-nav (sidenavToggle)="sidenav.toggle()"></iam-nav>
    <iam-quarterly-review-notification *ngIf="loggedIn$ | async"></iam-quarterly-review-notification>
    <iam-content
      class="bg-background flex-1"
      [ngClass]="{
        'signed-in': loggedIn$ | async,
        'signed-out': !(loggedIn$ | async)
      }"
    ></iam-content>
    <iam-footer></iam-footer>
  </mat-sidenav-content>
</mat-sidenav-container>