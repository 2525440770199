<mat-card appearance="outlined" class="main-card">
  <mat-card-content class="mat-body-1">
    <h1>{{ 'errorTexts.notFoundPage.header' | translate }}</h1>
    <p>{{ 'errorTexts.notFoundPage.body' | translate }}</p>

    <p-button
      type="button"
      [outlined]="true"
      severity="warning"
      label="{{ 'buttons.toHome' | translate }}"
      (click)="navigateToHome()"
    ></p-button>
  </mat-card-content>
</mat-card>
