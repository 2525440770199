<ng-container *ngIf="!(showLoader$ | async); else loading">
  <div *ngIf="portalInfo$ | async as portalInfo" class="flex flex-row mr-2 organization-selector">
    <mat-form-field appearance="outline" class="md:h-14 overflow-hidden text-ellipsis text-pb-blue-800 mt-3"
      *ngIf="portalInfo.organizations?.length > 1">
      <mat-select class="organization-selector" [value]="portalInfo.currentOrganization?.organization_id || 'empty'"
        (selectionChange)="changeSelectedOrganization($event)" data-test="organizations-dropdown"
        controlType='mat-select' hideSingleSelectionIndicator="true" class="leading-4">

        <mat-select-trigger>
          <div class="leading-3 md:text-pb-blue-800 text-white"
            *ngIf="portalInfo.currentOrganization?.organization_id else selectOrg">
            <div class="text-ellipsis overflow-hidden text-xs">
              {{(portalInfo$ | async | getOrgById:portalInfo.currentOrganization?.organization_id)?.name}}
            </div>
            <div style="width: 165px;" class="text-tiny flex flex-row overflow-hidden">
              <div class="max-w-60 overflow-ellipsis overflow-hidden">{{(portalInfo$ | async |
                getOrgById:portalInfo.currentOrganization?.organization_id)?.address}}, </div>
              <div class="max-w-40 overflow-ellipsis overflow-hidden">{{(portalInfo$ | async |
                getOrgById:portalInfo.currentOrganization?.organization_id)?.city}} </div>
            </div>
          </div>
          <!-- if no org is selected-->
          <ng-template #selectOrg>
            <div style="width: 160px; margin-top: 4px;" class="text-pb-blue-800">
              <div class="text-xs">
              {{ 'organizationDetails.formLabels.choose' | translate }}
            </div>
            </div>
          </ng-template>
        </mat-select-trigger>
        <mat-option value="empty" *ngIf="!portalInfo.currentOrganization?.organization_id">
          <div class="text-xs">{{
            'organizationDetails.formLabels.choose' | translate }}</div>
        </mat-option>
        <mat-option *ngFor="let org of portalInfo.organizations" [value]="org.organization_id"
          [attr.data-test]="'header-user-option-' + org.name.split(' ').join('-')" id="app" class="tracking-normal">
          <div class="leading-3 ">
            <h3 class="text-pb-grey-900 text-xs mb-0 leading-3">{{ org.name }} </h3>
            <div class="flex flex-row text-tiny text-pb-grey-900 leading-3 h-4 whitespace-nowrap">
              <div class="max-w-60 overflow-hidden overflow-ellipsis">{{ org.address }}, </div>
              <div class="max-w-40 overflow-hidden overflow-ellipsis">{{ org.city }}</div>
            </div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <span class="organization leading-3" style="margin-top: 7px;" *ngIf="portalInfo.organizations?.length === 1"
      data-test="single-organization-name">
      {{ portalInfo.currentOrganization?.name }}
      <div class="text-tiny flex flex-row">
        <div class="max-w-60">{{(portalInfo$ | async |
          getOrgById:portalInfo.currentOrganization?.organization_id)?.address}}, </div>
        <div class="max-w-40">{{(portalInfo$ | async |
          getOrgById:portalInfo.currentOrganization?.organization_id)?.city}} </div>
      </div>
    </span>
  </div>
</ng-container>
<ng-template #loading>
  <iam-skeleton-loader type="selector"></iam-skeleton-loader>
</ng-template>