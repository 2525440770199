import { Component} from '@angular/core';


@Component({
    selector: 'iam-customer-service-button',
    templateUrl: './customer-service-button.component.html',
    styleUrls: ['./customer-service-button.component.scss'],
    standalone: false
})
export class FeedbackButtonComponent {

  constructor(

  ) {}

  goToSupportSite() :void{
    window.open ('https://www.portbase.com/contact/', '_blank');
  }
}
